* {
  box-sizing: border-box;
}

h1,
h2,
h3,
p {
  font-weight: normal;
  font-size: 1em;
  margin: 0;
}
