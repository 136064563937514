.card {
  padding: 1em;

  div > * + * {
    margin-top: 0.5rem;
  }

  .name {
    font-size: 1.5em;
    font-weight: bold;
  }

  .higher-levels {
    margin-top: 1em;
  }

  .description > * + * {
    margin-top: 0.5em;
  }

  .collapsed {
    display: none;
  }
}
