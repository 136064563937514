.spell-selection {
  .grid {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    h1 {
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    .buttons {
      margin-bottom: 0.5em;
      > * + * {
        margin-left: 0.5em;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .selected {
    padding: 1em 0;

    > * + * {
      margin-top: 1em;
    }
  }

  .search-text > * + * {
    margin-left: 0.5em;
  }

  .spells {
    padding: 1em;
    max-height: 100vh;
    overflow: scroll;
    label {
      height: 1.4em;
    }
  }

  .selected-spells {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 20em;
    overflow: scroll;

    > * {
      width: 50%;
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 992px) {
    .collapsed {
      display: none;
    }

    .grid {
      display: flex;
      flex-direction: column-reverse;
    }

    .mobile {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1em;

      position: sticky;
      top: 0;
      padding: 1em;
      background: #eee;
    }

    .spells {
      padding: 0;
      max-height: initial;
      overflow: initial;
    }
  }
}
